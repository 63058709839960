<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="mb-0">Informações do arquivo</h4>
      <b-button
        variant="light-warning"
        class="bg-light-warning border-warning"
        @click="downloadFileByUrl"
      >
        <span class="mr-50 text-dark font-weight-bolder"> Baixar arquivo de retorno </span>
        <feather-icon icon="DownloadIcon" class="text-dark" />
      </b-button>
    </div>

    <div class="mt-2">
      <b-table ref="returnFile" class="border" :items="returnFile" :fields="returnFileFields">
        <template #cell(status)="data">
          <span v-html="data.value"></span>
        </template>
      </b-table>
    </div>

    <div class="mt-2">
      <datatable
        no-body
        ref="events"
        v-if="serviceUrl"
        :serviceUrl="serviceUrl"
        :customFields="eventsFields"
        showExportToExcell
        :exportToExcellAction="exportToExcel"
        :filters="customFilters"
      >
        <template #table-header>
          <div class="d-flex">
            <div class="d-flex justify-content-between align-items-center">
              <h4 class="mb-0">Eventos do arquivo</h4>
            </div>
            <div class="ml-auto">
              <feather-icon
                icon="FilterIcon"
                size="24"
                class="cursor-pointer text-secondary ml-1"
                @click="showFiltersButton = true"
              />
            </div>
            <!-- <div class="d-flex ml-auto">
              <b-form-input v-model="numeroTitulo" class="form-control-merge mr-2 ml-2" placeholder="Buscar por numero" />
              <v-select
                v-model="selectedInstrucao"
                :options="instrucoes"
                class="assignor-selector d-flex flex-column mr-2 mw-170"
                label="nome"
                placeholder="Instrução"
              >
              </v-select>
              <v-select
                v-model="selectedAcatado"
                :options="acatado"
                class="assignor-selector d-flex flex-column  mw-170"
                placeholder="Acatado"
              >
              </v-select>
            </div> -->
          </div>
        </template>
      </datatable>
      <datatable-filters-handler-sidebar
        :is-task-handler-sidebar-active="showFiltersButton"
        @closeSideBar="showFiltersButton = false"
        @updateFilterValues="updateFilterValues"
        :filters="customFilters"
      />
    </div>
  </b-card>
</template>

<script>
import { BCard, BTable, BButton, BFormInput } from 'bootstrap-vue'
import Datatable from '@/views/common/crud/components/Datatable.vue'
import vSelect from 'vue-select'
import {
  brazilianFormattedDate,
  returnFileStatusFormatter,
} from '@core/comp-functions/data_visualization/datatable'
import downloadFile from '@/utils/download-file'
import DatatableFiltersHandlerSidebar from '@/views/common/crud/components/DatatableFiltersHandlerSidebar.vue'

export default {
  name: 'ReturnFilesDetails',
  components: {
    BCard,
    BTable,
    BButton,
    Datatable,
    vSelect,
    BFormInput,
    DatatableFiltersHandlerSidebar,
  },
  data() {
    return {
      serviceUrl: '',
      returnFile: [],
      numeroTitulo: '',
      selectedInstrucao: '',
      selectedAcatado: '',
      showFiltersButton: false,
      datatableFilterValues: {},
      customFilters: [
        {
          category: 'Por parâmetro',
          fields: [
            {
              label: 'Número',
              key: 'numero_titulo',
              type: 'text',
            },
            {
              label: 'Acatado',
              key: 'acatado',
              type: 'select',
              options: [
                { value: 'sim', label: 'Sim' },
                { value: 'nao', label: 'Não' }
              ],
            },
            {
              label: 'Instrução',
              key: 'tipo',
              type: 'select',
              options: [
                { value: 'confirmacao_cessao', label: 'Confirmação de cessão' },
                { value: 'liquidacao', label: 'Liquidação' },
                { value: 'recompra', label: 'Recompra' },
                { value: 'cancelamento', label: 'Cancelamento' },
                { value: 'alongamento_prazo', label: 'Alogamento de prazo' },
                { value: 'reducao_valor', label: 'Redução de valor' },
                { value: 'substituicao_titulo', label: 'Substituição de título' }
              ],
            },
          ],
        },
      ],
      acatado: [
        { value: 'sim', label: 'Sim' },
        { value: 'nao', label: 'Não' }
      ],
      instrucoes: [
        { value: 'importado', label: 'Importado' },
        { value: 'disponivel', label: 'Disponivel' },
        { value: 'aguardando_assinatura', label: 'Aguardando assinatura' },
        { value: 'assinada', label: 'Assinada' },
        { value: 'concluido', label: 'Concluída' },
        { value: 'erro', label: 'Erro' },
        { value: 'cancelada', label: 'Cancelada' }
      ],
      returnFileFields: [
        {
          key: 'id',
          label: 'Número',
        },
        {
          key: 'cedente.nome',
          label: 'Cedente',
        },
        {
          key: 'created_at',
          label: 'Data de envio',
          formatter: brazilianFormattedDate,
        },
        {
          key: 'qtd_registros',
          label: 'Qtd. de eventos',
        },
        {
          key: 'status',
          label: 'Status',
          formatter: returnFileStatusFormatter,
        },
      ],
      eventsFields: [
        {
          key: 'numero_documento',
          label: 'Título',
        },
        {
          key: 'tipo',
          label: 'Instrução',
          formatter: (value) => {
            const options = {
              confirmacao_cessao: 'Confirmação de cessão',
              liquidacao: 'Liquidação',
              recompra: 'Recompra',
              cancelamento: 'Cancelamento',
              alongamento_prazo: 'Alongamento de prazo',
              reducao_valor: 'Redução de valor',
              substituicao_titulo: 'Substituição de título',
            }

            return options[value]
          },
        },
        {
          key: 'status',
          label: 'Status',
          formatter: (value) => {
            const status = {
              pendente: { value: 'Pendente', variant: 'info' },
              importado: { value: 'Importado', variant: 'warning' },
              processado: { value: 'Processado', variant: 'success' },
              erro_importacao: { value: 'Erro de importação', variant: 'danger' },
              erro_processamento: { value: 'Erro de processamento', variant: 'danger' },
            }[value]
            
            return `<span class="badge badge-light-${status.variant} badge-text-dark badge-pill">${status.value}</span>`
          },
        },
        {
          key: 'descricao',
          label: 'Descrição',
        },
        {
          key: 'erro_importacao',
          label: 'Motivo',
          formatter: (value, key, item) => {
            const motivo = item.erro_importacao || item.erro_processamento
            return motivo
          },
        },
      ],
      events: [],
    }
  },
  async mounted() {
    await this.getData()
    this.serviceUrl = `/admin/trademasters/retorno_bancario/${this.$route.params.id}/registros`
    this.verifyStatus()
  },
  methods: {
    async getData() {
      const { data } = await this.$store.dispatch('admin/getReturnFile', this.$route.params.id)
      this.returnFile = [data.data]
    },
    async getEvents() {
      const { data } = await this.$store.dispatch(
        'admin/getReturnFileEvents',
        this.$route.params.id,
      )
      this.events = data.data
    },
    downloadFileByUrl() {
      window.open(this.returnFile[0]?.arquivo.url, '_blank')
    },
    async exportToExcel() {
      try {
        const { data } = await this.$store.dispatch('admin/exportReturnFilesEvents', {
          id: this.$route.params.id,
          payload: this.$refs.events.params,
        })
        downloadFile(data, 'eventos.xlsx', 'text/xlsx')
      } catch (error) {}
    },
    updateFilterValues(values) {
      this.datatableFilterValues = values
    },
    async verifyStatus(){
      if(!['undefined', 'pendente'].includes(String(this.returnFile[0]?.status))) return
      await this.getData()
      if (this.returnFile[0]?.status !== 'pendente') this.getEvents()
      setTimeout(() => this.verifyStatus(), 10000) // 10 segundos
    }
  },
  computed: {
    params() {
      let params = {}
      if (this.datatableFilterValues) params = { ...params, ...this.datatableFilterValues }
      return params
    },
  },
  watch: {
    params(newValue, oldValue) {
      if (newValue !== oldValue) this.$refs.events.updateFilterValues(newValue)
    },
  },
}
</script>

<style lang="scss">
.mw-170 {
  min-width: 170px;
}
.mw-100 {
  min-width: 100px;
}
</style>
